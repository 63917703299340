import $ from 'jquery'
import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import Logo from './../../assets/images/logo.png'
import Search from './../../assets/images/search.svg'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import Cookies from 'universal-cookie'
import { HOSTNAME } from '../config'
const cookies = new Cookies()

let initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  err: {},
  resMessage: '',
  resType: '',
  isLoading: false,
}

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const user = cookies.get('user')
    if (user && user !== 'undefined') {
      // setTimeout(function() {
      navigate('/')
      // }, 1000)
    } else {
      setTimeout(function() {
        document.title = 'Sign Up | American Field'
        let meta = document.getElementsByTagName('meta')
        for (var i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = 'Sign Up | American Field'
          }
          if (meta[i].name.toLowerCase() === 'url') {
            meta[i].content = 'https://app.shopaf.co/signup'
          }
        }
      }, 300)
    }
  }

  handleChange(e) {
    if (e.target.name === 'firstName') {
      this.state.err.firstName = ''
    }
    if (e.target.name === 'lastName') {
      this.state.err.lastName = ''
    }
    if (e.target.name === 'email') {
      this.state.err.email = ''
    }
    // if (e.target.name === 'phoneNumber') {
    //   this.state.err.phoneNumber = ''
    // }
    this.setState({ [e.target.name]: e.target.value })
  }

  submitForm(event) {
    event.preventDefault()
    const { firstName, lastName, email, phoneNumber } = this.state
    let self = this
    const err = {}
    if (firstName === '' || firstName.trim() === '') {
      err.firstName = 'Please enter your first name'
    }
    if (lastName === '' || lastName.trim() === '') {
      err.lastName = 'Please enter your last name'
    }
    if (email === '' || email.trim() === '') {
      err.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email'
    }
    // if (phoneNumber === '' || phoneNumber.trim() === '') {
    //   err.phoneNumber = 'Please enter your phone number'
    // }
    this.setState({ err })
    if (!Object.keys(err).length) {
      window &&
        window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'Sign Up',
          eventAction: 'Sign Up Submit Clicked',
          eventLabel: 'Sign Up Submit Clicked',
          transport: '1',
        })
      this.setState({ isLoading: true })

      const params = new URLSearchParams(window.location.search)
      axios
        .post(HOSTNAME + 'signupv2', {
          firstName: firstName,
          lastName: lastName,
          email: String(email).toLowerCase(),
          phoneNumber: phoneNumber.replace(/\D/g, ''),
          ticketId: params.get('ticketId'),
        })
        .then(function(response) {
          let res = response.data
          if (res.success) {
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-success',
            })
            let expires = new Date()
            expires.setHours(expires.getHours() + 24)
            expires = new Date(expires)
            cookies.set('user', res.data, { path: '/', expires })
            //cookies.set('Authorization', res.token, { path: '/', expires });
            let navigatePath = '/'
            const brandDetail = cookies.get('branddetail')
            if (brandDetail) {
              navigatePath = brandDetail.url
            }

            setTimeout(function() {
              navigate(navigatePath)
              cookies.remove('branddetail')
              document.cookie = 'branddetail =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            }, 200)
          } else {
            self.setState({
              isLoading: false,
              resMessage: res.message,
              resType: 'alert alert-danger',
            })
            setTimeout(function() {
              self.setState({ resMessage: '', resType: '' })
            }, 2000)
          }
        })
        .catch(function(error) {
          self.setState({
            isLoading: false,
            resMessage: error,
            resType: 'alert alert-danger',
          })
        })
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      err,
      resMessage,
      resType,
      isLoading,
    } = this.state
    return (
      <div>
        <div className="login__page">
          <div className="login__header">
            <button className="btn header_btn">
              <Link to={'/login'}>Login</Link>
            </button>
          </div>
          <div className="vertical__align-center">
            <Form className="form">
              <div className="logo__box text-center">
                <img src={Logo} />
                <h2 className="form_title">Discover Brands | Earn Rewards</h2>
              </div>
              <div className="form__inner">
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="exampleEmail">FIRST NAME</Label>
                      <Input
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={this.handleChange}
                        placeholder=""
                      />
                      <FormFeedback>
                        {'firstName' in err ? err.firstName : ''}{' '}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <Label for="exampleEmail">LAST NAME</Label>
                      <Input
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={this.handleChange}
                        placeholder=""
                      />
                      <FormFeedback>
                        {'lastName' in err ? err.lastName : ''}{' '}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <FormGroup>
                      <Label for="exampleEmail">EMAIL <span>(Please use this email when purchasing a ticket)</span></Label>
                      <Input
                        type="text"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder=""
                      />
                      <FormFeedback>
                        {'email' in err ? err.email : ''}{' '}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  {/*<div className="col-md-6">
                    <FormGroup>
                      <Label for="exampleEmail">PHONE NUMBER</Label>
                      <NumberFormat
                        className="form-control"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={this.handleChange}
                        format="(###) ###-####"
                        mask="_"
                      />
                      <FormFeedback>
                        {'phoneNumber' in err ? err.phoneNumber : ''}{' '}
                      </FormFeedback>
                    </FormGroup>
                  </div>*/}
                </div>
              </div>
              {resMessage !== '' && (
                <div className={resType}>
                  <strong>{resMessage}</strong>
                </div>
              )}
              {isLoading ? (
                <Button className="btn btn-primary btn-block btn-lg signup_btn">
                  Requesting...
                </Button>
              ) : (
                <Button
                  className="btn btn-primary btn-block btn-lg signup_btn"
                  onClick={this.submitForm.bind(this)}
                >
                  SIGN UP
                </Button>
              )}
              <Link className="login_text text-center" to={'/login'}>
                Already have an account? <span>Login</span>
              </Link>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default Signup
