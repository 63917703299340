import React from 'react'
import Layout from '../components/layout'
import Signup from '../components/signup/component'

const NotFoundPage = props => (
  <Layout>
    <Signup {...props} />
  </Layout>
)

export default NotFoundPage
